import { Settings } from '@prisma/client'
import axios from 'axios'
import useSWR from 'swr'

import { configHeader } from '@/constants/api'
import { APP_URL } from '@/constants/env'

const useBarmanSettings = () => {
  const config = {
    method: 'get',
    withCredentials: true,
    ...configHeader,
  }

  const { data, error, isLoading, mutate } = useSWR(
    ['barmanSettings'],
    async () => axios(`${APP_URL}/api/settings`, config)
  )

  if (error) {
    console.error(error)
  }

  return {
    settings: data?.data as Settings[],
    isLoading,
    error,
    mutate,
  }
}

export default useBarmanSettings
