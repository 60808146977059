'use client'

import {
  RiBeerFill,
  RiReceiptLine,
  RiRestaurantFill,
  RiWalkLine,
} from '@remixicon/react'
import { Alert, Badge, Button, message, Tooltip } from 'antd'
import axios from 'axios'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import useCartState from '@/hooks/context/useCartState'
import useDrawerState from '@/hooks/context/useDrawerState'
import useBarmanSettings from '@/hooks/useBarmanSettings'

import Chatbot from '@/components/Chatbot'
import TableForm from '@/components/Chatbot/TableForm'

import { generateUUID } from '@/utils'

const Home = () => {
  const { table } = useCartState()
  const { t } = useTranslation()
  const [showIntro, setShowIntro] = useState(true)
  const { setIsCartOpen, cart } = useCartState()
  const { selectedConversation, setSelectedConversation } = useDrawerState()
  const { settings, mutate: mutateSettings } = useBarmanSettings()
  const isOffline =
    settings?.filter((s) => s.key === 'offline')[0]?.value === 'true'

  function setMessage(message: string) {
    if (message === 'SETTINGS_UPDATED') {
      mutateSettings()
    }
  }

  useEffect(() => {
    if (table) {
      const sse = new EventSource(`/api/event-stream?id=${table}`)
      sse.addEventListener('message', (e) => {
        setMessage(e.data)
      })

      sse.onerror = function (e) {
        if (e.eventPhase === EventSource.CLOSED) {
          sse?.close()
          setMessage('Event source closed')
        }
      }
      return () => {
        sse.close()
      }
    }
  }, [table])

  const startConversation = () => {
    if (!selectedConversation) {
      setSelectedConversation(generateUUID())
    }
  }

  const callBartender = async () => {
    try {
      await axios.post('/api/call-bartender', { table })
      message.success(t('bartender-called'))
    } catch (error) {
      console.error(error)
    }
  }

  if (!table) {
    return (
      <div className='h-screen content-center'>
        <TableForm />
      </div>
    )
  }

  if (showIntro) {
    return (
      <div className='m-auto flex h-screen max-w-screen-sm flex-col items-center justify-center gap-8 p-4'>
        <h1 className='text-5xl font-bold'>{t('intro-title')}</h1>
        <h3 className='text-xl font-semibold'>
          {t('table')} {table}
        </h3>
        <p className='text-xl'>{t('intro-info')}</p>
        {isOffline && (
          <Alert message={t('offline-info')} type='warning' showIcon />
        )}
        <div className='flex flex-col gap-6'>
          <Button
            size='large'
            type='primary'
            onClick={() => {
              startConversation()
              setShowIntro(false)
            }}
            icon={
              <div className='flex items-center'>
                <RiRestaurantFill className='size-5' />
                <RiBeerFill className='size-5' />
              </div>
            }
            disabled={isOffline}
          >
            {t('intro-button-text')}
          </Button>
          {isOffline ? (
            <Tooltip title={t('offline-info')}>
              <Button
                size='large'
                disabled
                icon={<RiWalkLine className='size-5' />}
              >
                {t('call-bartender')}
              </Button>
            </Tooltip>
          ) : (
            <Button
              size='large'
              onClick={callBartender}
              icon={<RiWalkLine className='size-5' />}
            >
              {t('call-bartender')}
            </Button>
          )}
        </div>
      </div>
    )
  }

  return (
    <>
      <div className='absolute z-10 flex w-full justify-between gap-2 bg-surface p-2 dark:bg-dark-surface'>
        {isOffline ? (
          <Tooltip title={t('offline-info')}>
            <Button
              onClick={callBartender}
              icon={<RiWalkLine className='size-4' />}
              disabled
            >
              {t('call-bartender')}
            </Button>
          </Tooltip>
        ) : (
          <Button
            onClick={callBartender}
            icon={<RiWalkLine className='size-4' />}
          >
            {t('call-bartender')}
          </Button>
        )}
        <Badge
          count={cart.reduce((acc, product) => acc + product.quantity, 0)}
          offset={[-6, 6]}
          color='red'
        >
          <Button
            onClick={() => setIsCartOpen(true)}
            icon={<RiReceiptLine className='size-4' />}
          >
            {t('order')}
          </Button>
        </Badge>
      </div>
      <Chatbot indexPage isOffline={isOffline} />
    </>
  )
}

export default Home
